import React from 'react';

const Labels = ({ first, last }) => (
    <>
        <span className={'button-label'}>
            <span>{first}</span>
            <span>{last}</span>
        </span>
        <span className={'button-hover'}>
            <span>{first}</span>
            <span>{last}</span>
        </span>
    </>
);

export default Labels;
