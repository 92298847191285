export const CURSOR_BLENDING = 'CURSOR_BLENDING';
export const CURSOR_PULSIVE = 'CURSOR_PULSIVE';
export const PREVIEW_ANIMATE = 'PREVIEW_ANIMATE';
export const NAV_OPENED = 'NAV_OPENED';
export const SET_ERROR = 'SET_ERROR';
export const GET_BIO = 'GET_BIO';
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_CERTIFICATES = 'GET_CERTIFICATES';
export const GET_COOPERATION = 'GET_COOPERATION';
export const GET_CONTACTS = 'GET_CONTACTS';
export const SET_MESSAGE = 'SET_MESSAGE';
export const GET_YOUTUBE_LINK = 'GET_YOUTUBE_LINK';
