import React from 'react';
import './block.scss';

const Block = ({ title = '', children }) => (
    <section className={'block'}>
        {title ? <h2 className={'block__title'}>{title}</h2> : ''}
        <div className={'block__content'}>{children}</div>
    </section>
);

export default Block;
