import { configureStore } from '@reduxjs/toolkit';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import rootReducer from './reducers';

const configureAppStore = preloadedState => {
    const store = configureStore({
        reducer: rootReducer,
        preloadedState,
        enhancers: [monitorReducersEnhancer]
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers', () =>
            store.replaceReducer(rootReducer)
        );
    }

    return store;
};

export default configureAppStore;
