import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { getBio } from '../../../redux/actions/bio';
import { PAGE_RESOLUTION } from '../../../vars';
import { capitalize } from '../../../helpers';
import Certificates from '../../parts/Certificates';
import Cooperation from '../../parts/Cooperation';
import Bio from '../../parts/Bio';
import Block from '../../parts/Block';
import Page from '../../parts/Page';
import Youtube from '../../parts/Youtube';

const PageBio = ({
    loaded,
    getBio,
    title,
    subtitle,
    photo,
    alt,
    first_name,
    last_name,
    short_desc,
    age,
    experience,
    nationality,
    location,
    full_desc,
    quote,
    languages,
    programming_languages,
    knowledge,
    toolkits
}) => {
    useEffect(() => {
        getBio();
    }, [getBio]);

    return (
        <>
            <Helmet>
                <link rel={'canonical'} href={`https://chernenko.digital/bio${PAGE_RESOLUTION}`} />
                <meta name={'description'} content={capitalize(subtitle)} />
                <title>{capitalize(title)} - Serhii Chernenko</title>
            </Helmet>
            <Page page={'bio'} loaded={loaded} title={title} subtitle={subtitle}>
                <div className={'row'}>
                    <div className={'col'}>
                        <Bio
                            photo={photo}
                            alt={alt}
                            first_name={first_name}
                            last_name={last_name}
                            short_desc={short_desc}
                            age={age}
                            experience={experience}
                            nationality={nationality}
                            location={location}
                            full_desc={full_desc}
                        />
                        <Block>
                            <blockquote className={'quote'}>{quote}</blockquote>
                        </Block>
                        <Block title={'languages'}>
                            <div className={'languages'}>
                                <table>
                                    <tbody>
                                        {languages.map(({ language, level }, index) => (
                                            <tr key={index}>
                                                <th>{language}:</th>
                                                <td>{level}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Block>
                    </div>
                    <div className={'col'}>
                        <Block title={'programming languages'}>{programming_languages}</Block>
                        <Block title={'knowledge'}>
                            <ul>
                                {knowledge.map((skill, index) => (
                                    <li key={index}>{skill}</li>
                                ))}
                            </ul>
                        </Block>
                        <Block title={'toolkits'}>
                            <ul>
                                {toolkits.map((tool, index) => (
                                    <li key={index}>{tool}</li>
                                ))}
                            </ul>
                        </Block>
                    </div>
                </div>
            </Page>
            <Youtube reverse={true} dark={true} />
            <Certificates />
            <Cooperation />
        </>
    );
};

const mapStateToProps = ({
    preview: { animate },
    bio: {
        loaded,
        page: { title, subtitle },
        data: {
            photo,
            alt,
            first_name,
            last_name,
            short_desc,
            age,
            experience,
            nationality,
            location,
            full_desc,
            quote,
            languages,
            programming_languages,
            knowledge,
            toolkits
        }
    }
}) => ({
    animate,
    loaded,
    title,
    subtitle,
    photo,
    alt,
    first_name,
    last_name,
    short_desc,
    age,
    experience,
    nationality,
    location,
    full_desc,
    quote,
    languages,
    programming_languages,
    knowledge,
    toolkits
});

const mapDispatchToProps = { getBio };

export default connect(mapStateToProps, mapDispatchToProps)(PageBio);
