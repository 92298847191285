import React from 'react';
import './bio.scss';
import { UPLOADS_DIR } from '../../../vars';

const Bio = ({
    full = true,
    photo,
    first_name,
    last_name,
    short_desc,
    age,
    experience,
    nationality,
    location,
    full_desc
}) => (
    <div className={`bio${full ? ' bio--full' : ''}`} itemScope itemType={'https://schema.org/Person'}>
        <div className={'bio__general'}>
            <div className={'bio__photo'}>
                <img
                    src={UPLOADS_DIR + photo}
                    alt={`${first_name} ${last_name}`}
                    loading={'lazy'}
                    width={200}
                    height={200}
                />
            </div>
            <div className={'bio__general__content'}>
                <div className={'bio__name'}>
                    <span itemProp={'givenName'}>{first_name}</span>
                    <br />
                    <span itemProp={'familyName'}>{last_name}</span>
                </div>
                {!full ? (
                    <p>
                        <span itemProp={'jobTitle'}>{short_desc}</span>
                    </p>
                ) : (
                    ''
                )}
            </div>
        </div>
        {full ? (
            <div className={'bio__content'}>
                <div className={'bio__info'}>
                    {full_desc.map((val, index) => (
                        <p key={index} itemProp={'jobTitle'}>
                            {val}
                        </p>
                    ))}
                </div>
                <table>
                    <tbody>
                        <tr>
                            <th>Age:</th>
                            <td>{age}</td>
                        </tr>
                        <tr>
                            <th>Experience:</th>
                            <td>{experience} years</td>
                        </tr>
                        <tr>
                            <th>Nationality:</th>
                            <td itemProp={'nationality'}>{nationality}</td>
                        </tr>
                        <tr>
                            <th>Location:</th>
                            <td itemProp={'homeLocation'}>{location}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        ) : (
            ''
        )}
    </div>
);

export default Bio;
