import { PREVIEW_ANIMATE } from '../actions/types';
import { MEDIA_DESKTOP } from '../../vars';
const animateState = window.matchMedia(MEDIA_DESKTOP).matches;

export default function preview(state = { animate: animateState }, { type, payload }) {
    switch (type) {
        case PREVIEW_ANIMATE: {
            const { animate } = payload;

            return {
                ...state,
                animate
            };
        }

        default:
            return state;
    }
}
