import React from 'react';
import { Link } from 'react-router-dom';
import { PAGE_RESOLUTION, UPLOADS_DIR } from '../../../vars';
import Button from '../../ui/Button';

const Item = ({ project: { key, label, desc, preview, date, stack } }) => {
    const projectId = `/projects/${key + PAGE_RESOLUTION}`;

    return (
        <li>
            <div className={'box-project'} itemScope itemType={'https://schema.org/WebSite'}>
                <Link to={projectId} title={label} className={'box-project__img'}>
                    <img src={UPLOADS_DIR + preview} alt={label} loading={'lazy'} />
                </Link>
                <div className={'box-project__content'}>
                    <h2 className={'box-project__title'} itemProp={'about'}>
                        {label}
                    </h2>
                    <h3 className={'box-project__desc'} itemProp={'abstract'}>
                        {desc}
                    </h3>
                    <div className={'box-project__stack'}>
                        <div className={'box-project__label'}>stack</div>
                        <div itemProp={'accessibilitySummary'}>{stack}</div>
                    </div>
                    <div className={'box-project__date'}>
                        <div className={'box-project__label'}>released</div>
                        <time itemProp={'datePublished'}>{date}</time>
                    </div>
                    <Button path={projectId} label={'open'} />
                </div>
            </div>
        </li>
    );
};

export default Item;
