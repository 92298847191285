import { GET_CONTACTS, SET_MESSAGE } from './types';
import axios from 'axios';
import { API_HOST } from '../vars';

const setMessage = payload => ({
    type: SET_MESSAGE,
    payload
});

export const getContacts = () => dispatch => {
    axios
        .get(`${API_HOST}/contact/get`)
        .then(({ data: { page, section, data } }) => {
            dispatch({
                type: GET_CONTACTS,
                payload: {
                    loaded: true,
                    page,
                    section,
                    data
                }
            });
        })
        .catch(error => console.log(error));
};

export const sendForm = (name, email, message) => dispatch => {
    let timer = () => {
        dispatch(
            setMessage({
                message: {
                    text: '',
                    type: ''
                }
            })
        );
    };

    clearTimeout(timer);

    dispatch(
        setMessage({
            message: {
                type: '',
                text: 'Processing...'
            }
        })
    );

    axios
        .post(`${API_HOST}/contact/post`, {
            name,
            email,
            message
        })
        .then(({ data: message }) => {
            dispatch(
                setMessage({
                    message
                })
            );
        })
        .catch(e => {
            dispatch(
                setMessage({
                    message: {
                        text: 'Something happened',
                        type: 'error'
                    }
                })
            );
            console.log(e);
        })
        .finally(() => {
            setTimeout(timer, 5000);
        });
};
