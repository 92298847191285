import React from 'react';
import { Link } from 'react-router-dom';
import Labels from './labels';
import './button.scss';

const Button = ({
    path = '',
    label = 'open',
    external = !!path.match('http'),
    target = external ? '_blank' : '_self'
}) => {
    const first = label.substr(0, label.length / 2);
    const last = label.substr(label.length / 2, label.length);
    const rel = external ? { rel: 'external noreferrer nofollow' } : {};

    return (
        <>
            {path ? (
                external ? (
                    <a href={path} className={'button'} target={target} {...rel}>
                        <Labels first={first} last={last} />
                    </a>
                ) : (
                    <Link to={path} className={'button'}>
                        <Labels first={first} last={last} />
                    </Link>
                )
            ) : (
                <button className={'button'}>
                    <Labels first={first} last={last} />
                </button>
            )}
        </>
    );
};

export default Button;
