import { GET_PROJECTS, GET_PROJECT, SET_ERROR } from '../actions/types';

export default (
    state = {
        loaded: false,
        error: false,
        section: {
            title: '',
            subtitle: ''
        },
        page: {
            title: '',
            subtitle: ''
        },
        projects: [],
        project: {
            label: '',
            desc: '',
            preview: '',
            date: '',
            logo: '',
            stack: '',
            work_label: '',
            work: [],
            url: '',
            repo: ''
        }
    },
    { type, payload }
) => {
    switch (type) {
        case GET_PROJECTS: {
            const { loaded, section, page, projects } = payload;

            return {
                ...state,
                loaded,
                section,
                page,
                projects
            };
        }

        case GET_PROJECT: {
            const { loaded, error, project } = payload;

            return {
                ...state,
                loaded,
                error,
                project
            };
        }

        case SET_ERROR: {
            return {
                ...state,
                loaded: true,
                error: true
            };
        }

        default:
            return state;
    }
};
