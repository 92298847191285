import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PAGE_RESOLUTION } from './vars';
import Home from './components/pages/Home';
import Bio from './components/pages/Bio';
import Projects from './components/pages/Projects';
import Project from './components/pages/Project';
import Contact from './components/pages/Contact';

const Routes = () => (
    <Switch>
        <Route exact path={'/admin'} render={() => null} />
        <Route exact path={'/'} component={Home} />
        <Route path={`/bio${PAGE_RESOLUTION}`} component={Bio} />
        <Route path={`/projects${PAGE_RESOLUTION}`} component={Projects} />
        <Route path={`/projects/:key${PAGE_RESOLUTION}`} component={Project} />
        <Route path={`/contact${PAGE_RESOLUTION}`} component={Contact} />
        <Redirect to={'/'} />
    </Switch>
);

export default Routes;
