import React from 'react';
import { connect } from 'react-redux';
import { sendForm } from '../../../redux/actions/contacts';
import Button from '../../ui/Button';
import Message from '../Message';
import './form.scss';

const Form = ({ sendForm, text, type }) => {
    const focusedClass = 'is-focused';

    const resetForm = event => {
        event.target.querySelectorAll('.input-field').forEach(field => field.classList.remove(focusedClass));

        event.target.querySelectorAll('input').forEach(input => (input.value = ''));

        event.target.querySelector('textarea').value = '';
    };

    const submit = event => {
        event.preventDefault();

        sendForm(
            event.target.querySelector('#name').value,
            event.target.querySelector('#email').value,
            event.target.querySelector('#message').value
        );

        resetForm(event);
    };

    const focusLabel = event => {
        const input = event.target.parentNode.querySelector('input');
        const textarea = event.target.parentNode.querySelector('textarea');

        if (input) {
            input.focus();
        } else if (textarea) {
            textarea.focus();
        }
    };

    const focusInput = event => {
        event.target.parentNode.classList.add(focusedClass);
    };

    const blurInput = event => {
        if (!event.target.value.length) {
            event.target.parentNode.classList.remove(focusedClass);
        }
    };

    return (
        <form onSubmit={submit}>
            <Message text={text} type={type} />
            <div className={'input-field'}>
                <label htmlFor={'name'} onClick={focusLabel}>
                    Name
                </label>
                <input id={'name'} type={'text'} onFocus={focusInput} onBlur={blurInput} />
            </div>
            <div className={'input-field'}>
                <label htmlFor={'email'} onClick={focusLabel}>
                    E-mail<span className={'asterisk'}>*</span>
                </label>
                <input id={'email'} type={'text'} required onFocus={focusInput} onBlur={blurInput} />
            </div>
            <div className={'input-field'}>
                <label htmlFor={'message'} onClick={focusLabel}>
                    Message<span className={'asterisk'}>*</span>
                </label>
                <textarea id={'message'} required minLength={'6'} onFocus={focusInput} onBlur={blurInput} />
            </div>
            <div className={'button-field'}>
                <Button label={'send'} />
            </div>
        </form>
    );
};

const mapStateToProps = ({
    contacts: {
        message: { text, type }
    }
}) => ({
    text,
    type
});

const mapDispatchToProps = { sendForm };

export default connect(mapStateToProps, mapDispatchToProps)(Form);
