import React, { useEffect, useState } from 'react';

const Typing = ({ loaded, children, speed = 80 }) => {
    const [typing, updateTyping] = useState([]);
    const [cursor, updateCursor] = useState(true);

    useEffect(() => {
        const array = children.split('');
        let tasks = [];

        for (let i = 0; i < array.length; i++) {
            tasks.push(
                setTimeout(() => {
                    updateTyping(oldArray => [...oldArray, array[i]]);

                    if (i === array.length - 1) updateCursor(false);
                }, i * speed)
            );
        }

        return () => {
            tasks.map(task => clearTimeout(task));
            updateTyping(() => []);
        };
    }, [speed, children]);

    useEffect(() => {
        return () => {
            updateTyping(() => []);
        };
    }, [loaded]);

    return (
        <>
            {typing.join('')}
            {cursor ? '|' : ''}
        </>
    );
};

export default Typing;
