import React from 'react';
import Loader from '../Loader';
import './page.scss';
import Typing from '../Typing';

const Page = ({ loaded, page, title, subtitle, children }) => (
    <main className={`page page-${page}`}>
        <div className={`container${loaded ? '' : ' container--loading'}`}>
            {loaded ? (
                <>
                    <section className={'page-title'}>
                        <h1>{title}</h1>
                        <h2>
                            <Typing loaded={loaded}>{subtitle}</Typing>
                        </h2>
                    </section>
                    {children}
                </>
            ) : (
                <Loader />
            )}
        </div>
    </main>
);

export default Page;
