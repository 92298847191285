import axios from 'axios';
import { GET_YOUTUBE_LINK } from './types';
import { API_HOST } from '../vars';

export const getYoutubeLink = () => dispatch => {
    axios
        .get(`${API_HOST}/youtube/get`)
        .then(({ data: { section, youtube } }) => {
            dispatch({
                type: GET_YOUTUBE_LINK,
                payload: {
                    loaded: true,
                    section,
                    youtube
                }
            });
        })
        .catch(error => console.log(error));
};
