import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCertificates } from '../../../redux/actions/certificates';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Section from '../Section';
import { UPLOADS_DIR } from '../../../vars';
import './certificates.scss';

const Certificates = ({ getCertificates, loaded, title, subtitle, certificates, dark = false, reverse = false }) => {
    useEffect(() => {
        getCertificates();
    }, [getCertificates]);

    return (
        <Section
            loaded={loaded}
            dark={dark}
            reverse={reverse}
            titleTag={'h1'}
            title={title}
            subtitle={subtitle}
            addClass={'certificates'}
        >
            <Carousel
                infiniteLoop={true}
                autoPlay={true}
                interval={5000}
                transitionTime={500}
                showIndicators={false}
                showThumbs={false}
                swipeable={true}
                emulateTouch={true}
                statusFormatter={(currentItem, total) => `${currentItem} / ${total}`}
                width={500}
            >
                {certificates.map(({ title, img, date }, index) => (
                    <div key={index}>
                        <img
                            src={UPLOADS_DIR + img}
                            alt={`${title} | ${date}`}
                            width={500}
                            height={380}
                            loading={'lazy'}
                        />
                        <p className={'legend'}>
                            {title}
                            <br />
                            {date}
                        </p>
                    </div>
                ))}
            </Carousel>
        </Section>
    );
};

const mapStateToProps = ({
    certificates: {
        loaded,
        section: { title, subtitle },
        data: { certificates }
    }
}) => ({
    loaded,
    title,
    subtitle,
    certificates
});

const mapDispatchToProps = { getCertificates };

export default connect(mapStateToProps, mapDispatchToProps)(Certificates);
