import { GET_CONTACTS, SET_MESSAGE } from '../actions/types';

export default (
    state = {
        loaded: false,
        section: {
            title: '',
            subtitle: ''
        },
        page: {
            title: '',
            subtitle: ''
        },
        data: [],
        message: {
            text: '',
            type: ''
        }
    },
    { type, payload }
) => {
    switch (type) {
        case GET_CONTACTS: {
            const { loaded, section, page, data } = payload;

            return {
                ...state,
                loaded,
                section,
                page,
                data
            };
        }

        case SET_MESSAGE: {
            const { message } = payload;

            return {
                ...state,
                message
            };
        }

        default:
            return state;
    }
};
