export const PAGE_RESOLUTION = '.html';
export const MEDIA_DESKTOP = '(min-width: 1025px)';
export const NAV_LINKS = [
    {
        url: '/',
        label: 'Home'
    },
    {
        url: '/bio',
        label: 'Bio'
    },
    {
        url: '/projects',
        label: 'Projects'
    },
    {
        url: '/contact',
        label: 'Contact'
    }
];
export const UPLOADS_DIR = 'https://chernenko.digital/uploads/';
