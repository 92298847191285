import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProject } from '../../../redux/actions/projects';
import { PAGE_RESOLUTION, UPLOADS_DIR } from '../../../vars';
import { capitalize } from '../../../helpers';
import Button from '../../ui/Button';
import Page from '../../parts/Page';
import './project.scss';
import { Helmet } from 'react-helmet-async';

const PageProject = ({
    match: {
        params: { key }
    },
    getProject,
    loaded,
    error,
    label,
    desc,
    preview,
    date,
    logo,
    stack,
    work_label,
    work,
    url,
    repo
}) => {
    const projectsPageUrl = `/projects${PAGE_RESOLUTION}`;

    useEffect(() => {
        getProject(key);
    }, [key, getProject]);

    if (error) return <Redirect to={projectsPageUrl} />;

    return (
        <>
            <Helmet>
                <link rel={'canonical'} href={`https://chernenko.digital/projects/${key + PAGE_RESOLUTION}`} />
                <meta name={'description'} content={desc} />
                <title>{capitalize(label)} - Projects - Serhii Chernenko</title>
            </Helmet>
            <Page page={'project'} loaded={loaded} title={label} subtitle={desc}>
                <div className={'project-content'} itemScope itemType={'https://schema.org/WebSite'}>
                    <div className={'project-content__btn'}>
                        <Button path={projectsPageUrl} label={'back'} />
                    </div>
                    <div className={'project-content__columns'}>
                        <div className={'project-content__columns__img'}>
                            <img src={UPLOADS_DIR + preview} alt={label} loading={'lazy'} />
                        </div>
                        <div className={'project-content__columns__content'}>
                            {logo ? (
                                <div className={'project-content__logo'}>
                                    <img src={UPLOADS_DIR + logo} alt={label} loading={'lazy'} />
                                </div>
                            ) : (
                                ''
                            )}
                            <div className={'project-content__date'}>
                                <div className={'project-content__label'}>when released:</div>
                                <time className={'project-content__value'} itemProp={'datePublished'}>
                                    {date}
                                </time>
                            </div>
                            <div className={'project-content__stack'}>
                                <div className={'project-content__label'}>used technology stack:</div>
                                <div className={'project-content__value'} itemProp={'accessibilitySummary'}>
                                    {stack}
                                </div>
                            </div>
                            {work.length ? (
                                <div className={'project-content__work'}>
                                    <div className={'project-content__label'}>
                                        {work_label ? work_label : 'what I did:'}
                                    </div>
                                    <ul className={'project-content__values'}>
                                        {work.map((item, index) => (
                                            <li key={index} itemProp={'accessibilitySummary'}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className={'project-content__buttons'}>
                                {url ? <Button path={url} label={'Web'} /> : ''}
                                {repo ? <Button path={repo} label={'Repo'} /> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </>
    );
};

const mapStateToProps = ({
    projects: {
        loaded,
        error,
        project: { label, desc, preview, date, logo, stack, work_label, work, url, repo }
    }
}) => ({
    loaded,
    error,
    label,
    desc,
    preview,
    date,
    logo,
    stack,
    work_label,
    work,
    url,
    repo
});

const mapDispatchToProps = { getProject };

export default connect(mapStateToProps, mapDispatchToProps)(PageProject);
