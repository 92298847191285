import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PAGE_RESOLUTION } from '../../../vars';
import { getContacts } from '../../../redux/actions/contacts';
import Section from '../Section';
import Form from '../Form';

const Contacts = ({ getContacts, loaded, title, subtitle, dark = true, reverse = true }) => {
    useEffect(() => {
        getContacts();
    }, [getContacts]);

    return (
        <Section
            loaded={loaded}
            reverse={reverse}
            dark={dark}
            titleTag={'h1'}
            title={title}
            subtitle={subtitle}
            link={`/contact${PAGE_RESOLUTION}`}
            centerDesktopContent={true}
            centerMobileContent={true}
            contentWidth={'300px'}
        >
            <Form />
        </Section>
    );
};

const mapStateToProps = ({
    contacts: {
        loaded,
        section: { title, subtitle }
    }
}) => ({
    loaded,
    title,
    subtitle
});

const mapDispatchToProps = { getContacts };

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
