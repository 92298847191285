import axios from 'axios';
import { GET_PROJECTS, GET_PROJECT, SET_ERROR } from './types';
import { API_HOST } from '../vars';

export const getProjects = filter => dispatch => {
    axios
        .get(`${API_HOST}/projects/get`, {
            params: {
                filter
            }
        })
        .then(({ data: { section, page, projects } }) => {
            dispatch({
                type: GET_PROJECTS,
                payload: {
                    loaded: true,
                    error: false,
                    section,
                    page,
                    projects
                }
            });
        })
        .catch(error => console.log(error));
};

export const getProject = projectKey => dispatch => {
    axios
        .get(`${API_HOST}/project/get`, {
            params: {
                projectKey
            }
        })
        .then(({ data }) => {
            if (data) {
                dispatch({
                    type: GET_PROJECT,
                    payload: {
                        loaded: true,
                        error: false,
                        project: data
                    }
                });
            } else {
                dispatch({
                    type: SET_ERROR
                });
            }
        })
        .catch(error => console.log(error));
};
