import { GET_COOPERATION } from '../actions/types';

export default (
    state = { loaded: false, section: { title: '', subtitle: '' }, data: { companies: [] } },
    { type, payload }
) => {
    switch (type) {
        case GET_COOPERATION: {
            const { loaded, section, data } = payload;

            return {
                ...state,
                loaded,
                section,
                data
            };
        }

        default:
            return state;
    }
};
