import { CURSOR_BLENDING, CURSOR_PULSIVE } from '../actions/types';

const round = number => Math.round(number * 100) / 100;

const monitorReducerEnhancer = createStore => (reducer, initialState, enhancer) => {
    const monitoredReducer = (state, action) => {
        const start = performance.now();
        const newState = reducer(state, action);
        const end = performance.now();
        const diff = round(end - start);

        if (
            process.env.NODE_ENV !== 'production' &&
            action.type !== CURSOR_BLENDING &&
            action.type !== CURSOR_PULSIVE
        ) {
            console.group(action.type);
            console.log('Reducer process time:', diff);
            console.log('Payload:', action.payload);
            console.groupEnd();
        }

        return newState;
    };

    return createStore(monitoredReducer, initialState, enhancer);
};

export default monitorReducerEnhancer;
