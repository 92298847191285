import { GET_YOUTUBE_LINK } from '../actions/types';

export default (
    state = {
        loaded: false,
        section: {
            title: '',
            subtitle: ''
        },
        youtube: {
            mainVideoLink: '',
            mainVideoTitle: '',
            channelLink: ''
        }
    },
    { type, payload }
) => {
    switch (type) {
        case GET_YOUTUBE_LINK: {
            return {
                ...state,
                ...payload
            };
        }

        default:
            return state;
    }
};
