import { NAV_OPENED } from '../actions/types';

export default function nav(state = { opened: false }, { type, payload }) {
    switch (type) {
        case NAV_OPENED: {
            const { opened } = payload;

            return {
                ...state,
                opened
            };
        }

        default:
            return state;
    }
}
