import { combineReducers } from 'redux';
import preview from './preview';
import nav from './nav';
import bio from './bio';
import certificates from './certificates';
import cooperation from './cooperation';
import projects from './projects';
import contacts from './contacts';
import youtube from './youtube';

export default combineReducers({
    preview,
    nav,
    bio,
    certificates,
    cooperation,
    projects,
    contacts,
    youtube
});
