import React from 'react';
import logo from '../../../img/logo.svg';
import './logo.scss';

const Logo = () => (
    <div className={'logo'}>
        <div>
            <img src={logo} alt={'Logo'} width={200} height={83} loading={'lazy'} />
        </div>
    </div>
);

export default Logo;
