import { GET_BIO } from '../actions/types';

export default (
    state = {
        loaded: false,
        section: {
            title: '',
            subtitle: ''
        },
        page: {
            title: '',
            subtitle: ''
        },
        data: {
            first_name: '',
            last_name: '',
            photo: '',
            age: '',
            experience: '',
            nationality: '',
            location: '',
            short_desc: '',
            full_desc: [],
            quote: '',
            languages: [],
            programming_languages: '',
            knowledge: [],
            toolkits: []
        }
    },
    { type, payload }
) => {
    switch (type) {
        case GET_BIO: {
            return {
                ...state,
                ...payload
            };
        }

        default:
            return state;
    }
};
