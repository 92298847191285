import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { previewAnimate } from '../../../redux/actions/preview';
import './preview.scss';

const Preview = ({ animate, previewAnimate }) => {
    useEffect(() => {
        setTimeout(() => previewAnimate(false), 1000);
    }, [previewAnimate]);

    return (
        <div className={`preview${animate ? ' is-animated' : ''}`}>
            <div>
                <span>c</span>
                <span>h</span>
                <span>e</span>
                <span>r</span>
                <span>n</span>
                <span>e</span>
                <span>n</span>
                <span>k</span>
                <span>o</span>
            </div>
        </div>
    );
};

const mapStateToProps = ({ preview: { animate } }) => ({
    animate
});

const mapDispatchToProps = { previewAnimate };

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
