import React from 'react';
import './message.scss';

const Message = ({ type, text }) => (
    <>
        {text ? (
            <div
                className={`message${type === 'error' ? ' message--error' : ''}${
                    type === 'success' ? ' message--success' : ''
                }`}
            >
                {text}
            </div>
        ) : (
            ''
        )}
    </>
);

export default Message;
