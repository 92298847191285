import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getProjects } from '../../../redux/actions/projects';
import { PAGE_RESOLUTION, UPLOADS_DIR } from '../../../vars';
import Section from '../Section';

const Projects = ({ loaded, dark, reverse, getProjects, title, subtitle, projects }) => {
    useEffect(() => {
        getProjects(true);
    }, [getProjects]);

    return (
        <Section
            loaded={loaded}
            dark={dark}
            reverse={reverse}
            titleTag={'h1'}
            title={title}
            subtitle={subtitle}
            link={`/projects${PAGE_RESOLUTION}`}
        >
            <ul className={'section-list'}>
                {projects.map(({ label, logo, key }, index) => (
                    <li key={index}>
                        <Link to={`/projects/${key + PAGE_RESOLUTION}`} title={label}>
                            <img src={UPLOADS_DIR + logo} alt={label} width={130} height={30} loading={'lazy'} />
                        </Link>
                    </li>
                ))}
            </ul>
        </Section>
    );
};

const mapStateToProps = ({
    projects: {
        loaded,
        section: { title, subtitle },
        projects
    }
}) => ({
    loaded,
    title,
    subtitle,
    projects
});

const mapDispatchToProps = { getProjects };

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
