import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getContacts } from '../../../redux/actions/contacts';
import { PAGE_RESOLUTION } from '../../../vars';
import { capitalize } from '../../../helpers';
import Button from '../../ui/Button';
import Page from '../../parts/Page';
import './contact.scss';
import Form from '../../parts/Form';
import { Helmet } from 'react-helmet-async';

const PageContact = ({ getContacts, loaded, title, subtitle, links }) => {
    useEffect(() => {
        getContacts();
    }, [getContacts]);

    return (
        <>
            <Helmet>
                <link rel={'canonical'} href={`https://chernenko.digital/contact${PAGE_RESOLUTION}`} />
                <meta name={'description'} content={capitalize(subtitle)} />
                <title>{capitalize(title)} - Serhii Chernenko</title>
            </Helmet>
            <Page page={'contact'} loaded={loaded} title={title} subtitle={subtitle}>
                <div className={'page-contact__columns'} itemScope itemType={'https://schema.org/ContactPage'}>
                    <div className={'page-contact__column'}>
                        <ul className='page-contact__social'>
                            {links.map(({ label, link }, index) => (
                                <li key={index} itemProp={'relatedLink'}>
                                    <Button path={link} label={label} />
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className={'page-contact__column'}>
                        <Form />
                    </div>
                </div>
            </Page>
        </>
    );
};

const mapStateToProps = ({
    contacts: {
        loaded,
        page: { title, subtitle },
        data
    }
}) => ({
    loaded,
    title,
    subtitle,
    links: data
});

const mapDispatchToProps = { getContacts };

export default connect(mapStateToProps, mapDispatchToProps)(PageContact);
