import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getProjects } from '../../../redux/actions/projects';
import { PAGE_RESOLUTION } from '../../../vars';
import { capitalize } from '../../../helpers';
import Page from '../../parts/Page';
import Item from './item';
import './projects.scss';
import { Helmet } from 'react-helmet-async';

const PageProjects = ({ loaded, getProjects, title, subtitle, projects }) => {
    useEffect(() => {
        getProjects();
    }, [getProjects]);

    return (
        <>
            <Helmet>
                <link rel={'canonical'} href={`https://chernenko.digital/projects${PAGE_RESOLUTION}`} />
                <meta name={'description'} content={capitalize(subtitle)} />
                <title>{capitalize(title)} - Serhii Chernenko</title>
            </Helmet>
            <Page page={'projects'} loaded={loaded} title={title} subtitle={subtitle}>
                <ul className={'projects-list'}>
                    {projects.map((project, index) => (
                        <Item key={index} project={project} />
                    ))}
                </ul>
            </Page>
        </>
    );
};

const mapStateToProps = ({
    projects: {
        loaded,
        page: { title, subtitle },
        projects
    }
}) => ({
    loaded,
    title,
    subtitle,
    projects
});

const mapDispatchToProps = { getProjects };

export default connect(mapStateToProps, mapDispatchToProps)(PageProjects);
