import React, { useEffect, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';
import { getBio } from '../../../redux/actions/bio';
import { PAGE_RESOLUTION } from '../../../vars';
import './home.scss';
import Section from '../../parts/Section';
import Preview from '../../parts/Preview';
import Logo from '../../parts/Logo';
import Scroll from '../../parts/Scroll';
import Bio from '../../parts/Bio';
import Projects from '../../parts/Projects';
import Certificates from '../../parts/Certificates';
import Cooperation from '../../parts/Cooperation';
import Contacts from '../../parts/Contacts';
import Youtube from '../../parts/Youtube';

const Particles = lazy(() => import('../../parts/Particles'));

const Home = ({
    animate,
    getBio,
    loaded,
    title,
    subtitle,
    photo,
    first_name,
    last_name,
    short_desc,
    age,
    experience,
    nationality,
    location,
    full_desc
}) => {
    useEffect(() => {
        getBio(false);
    }, [getBio]);

    return (
        <div className={`page-home${animate ? ' is-previewing' : ''}`}>
            <Helmet>
                <link rel={'canonical'} href={'https://chernenko.digital/'} />
                <meta name={'description'} content={short_desc} />
                <title>
                    {first_name} {last_name} - {short_desc}
                </title>
            </Helmet>
            <Preview />
            <main className={'page-home__main'}>
                <Suspense fallback={null}>
                    <Particles />
                </Suspense>
                <Logo />
                <Scroll />
            </main>
            <Section
                loaded={loaded}
                dark={true}
                reverse={true}
                titleTag={'h1'}
                title={title}
                subtitle={subtitle}
                link={`/bio${PAGE_RESOLUTION}`}
                centerMobileContent={true}
            >
                <Bio
                    full={false}
                    photo={photo}
                    first_name={first_name}
                    last_name={last_name}
                    short_desc={short_desc}
                    age={age}
                    experience={experience}
                    nationality={nationality}
                    location={location}
                    full_desc={full_desc}
                />
            </Section>
            <Youtube />
            <Projects dark={true} reverse={true} />
            <Cooperation dark={false} reverse={false} />
            <Certificates dark={true} reverse={true} />
            <Contacts dark={false} reverse={false} />
        </div>
    );
};

const mapStateToProps = ({
    preview: { animate },
    bio: {
        loaded,
        section: { title, subtitle },
        data: { photo, first_name, last_name, short_desc, age, experience, nationality, location, full_desc }
    }
}) => ({
    animate,
    loaded,
    title,
    subtitle,
    photo,
    first_name,
    last_name,
    short_desc,
    age,
    experience,
    nationality,
    location,
    full_desc
});

const mapDispatchToProps = { getBio };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
