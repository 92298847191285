import axios from 'axios';
import { GET_CERTIFICATES } from './types';
import { API_HOST } from '../vars';

export const getCertificates = () => dispatch => {
    axios
        .get(`${API_HOST}/certificates/get`)
        .then(({ data: { section, data } }) => {
            dispatch({
                type: GET_CERTIFICATES,
                payload: {
                    loaded: true,
                    section,
                    data
                }
            });
        })
        .catch(error => console.log(error));
};
