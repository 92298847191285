import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getCooperation } from '../../../redux/actions/cooperation';
import Section from '../Section';
import { UPLOADS_DIR } from '../../../vars';

const Cooperation = ({ getCooperation, loaded, title, subtitle, companies, dark = true, reverse = true }) => {
    useEffect(() => {
        getCooperation();
    }, [getCooperation]);

    return (
        <Section loaded={loaded} dark={dark} reverse={reverse} titleTag={'h1'} title={title} subtitle={subtitle}>
            <ul className={'section-list'} itemScope itemType={'https://schema.org/Person'}>
                {companies.map(({ label, logo, url }, index) => (
                    <li key={index}>
                        <a href={url} title={label} target={'_blank'} rel={'noopener noreferrer'} itemProp={'worksFor'}>
                            <img src={UPLOADS_DIR + logo} alt={label} loading={'lazy'} width={130} height={30} />
                        </a>
                    </li>
                ))}
            </ul>
        </Section>
    );
};

const mapStateToProps = ({
    cooperation: {
        loaded,
        section: { title, subtitle },
        data: { companies }
    }
}) => ({
    loaded,
    title,
    subtitle,
    companies
});

const mapDispatchToProps = { getCooperation };

export default connect(mapStateToProps, mapDispatchToProps)(Cooperation);
