import React from 'react';
import Loader from '../Loader';
import Button from '../../ui/Button';
import './section.scss';

export default ({
    loaded = true,
    reverse = false,
    dark = false,
    addClass = false,
    titleTag = 'h2',
    title,
    subtitle,
    link = false,
    centerDesktopContent = false,
    centerMobileContent = false,
    contentWidth = 'auto',
    children
}) => (
    <section
        className={`section${reverse ? ' section--reverse' : ''}${dark ? ' section--dark' : ''}${
            addClass ? ` ${addClass}` : ''
        }${loaded ? '' : ' section--loading'}`}
    >
        {loaded ? (
            <div className={'section__container'}>
                <div className={'section__row'}>
                    <div className={'section__col section__col--text'}>
                        {titleTag === 'h1' ? <h1>{title}</h1> : <h2>{title}</h2>}
                        <h3>{subtitle}</h3>
                        {link ? <Button path={link} /> : ''}
                    </div>
                    <div
                        className={`section__col section__col--content${
                            centerDesktopContent ? ' section__col--content--center-desk' : ''
                        }${centerMobileContent ? ' section__col--content--center-mob' : ''}`}
                    >
                        <div style={{ width: contentWidth }}>{children}</div>
                    </div>
                </div>
            </div>
        ) : (
            <Loader />
        )}
    </section>
);
