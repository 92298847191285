import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getYoutubeLink } from '../../../redux/actions/youtube';
import Section from '../Section';
import './youtube.scss';

const Youtube = ({
    loaded,
    dark,
    reverse,
    getYoutubeLink,
    title,
    subtitle,
    mainVideoLink,
    mainVideoTitle,
    channelLink
}) => {
    useEffect(() => {
        getYoutubeLink();
    }, [getYoutubeLink]);

    return (
        <Section
            loaded={loaded}
            titleTag={'h1'}
            title={title}
            subtitle={subtitle}
            dark={dark}
            reverse={reverse}
            link={channelLink}
        >
            <iframe
                width='560'
                height='315'
                src={mainVideoLink}
                title={mainVideoTitle}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                className={'youtube-iframe'}
            ></iframe>
        </Section>
    );
};

const mapStateToProps = ({
    youtube: {
        loaded,
        section: { title, subtitle },
        youtube: { mainVideoLink, mainVideoTitle, channelLink }
    }
}) => ({
    loaded,
    title,
    subtitle,
    mainVideoLink,
    mainVideoTitle,
    channelLink
});

const mapDispatchToProps = { getYoutubeLink };

export default connect(mapStateToProps, mapDispatchToProps)(Youtube);
