import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './router';
import './App.scss';
import Nav from './components/parts/Nav';
import ScrollToTop from './components/parts/ScrollToTop';

const App = () => (
    <BrowserRouter>
        <ScrollToTop />
        <Nav />
        <Routes />
    </BrowserRouter>
);

export default App;
