import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { navOpened } from '../../../redux/actions/nav';
import { NAV_LINKS, PAGE_RESOLUTION } from '../../../vars';
import './nav.scss';

const Nav = ({ opened, navOpened }) => {
    const resetNav = () => navOpened(false);

    return (
        <>
            <button
                className={`toggle-nav${opened ? ' is-active' : ''}`}
                onClick={() => (opened ? navOpened(false) : navOpened(true))}
            >
                <span />
                <span />
                <span />
            </button>
            <nav className={`nav${opened ? ' is-active' : ''}`}>
                <div className={'nav__overlay'} onClick={resetNav} />
                <ul className={'nav-list'}>
                    {NAV_LINKS.map(({ url, label }, index) => (
                        <li key={index}>
                            <Link to={url + PAGE_RESOLUTION} onClick={resetNav}>
                                {label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    );
};

const mapStateToProps = ({ nav: { opened } }) => ({
    opened
});

const mapDispatchToProps = { navOpened };

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
